import revive_payload_client_buxRE0OYCt from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_6xuprgvctrpmuyfuh2n3ef5gpm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_idEAEOk5pB from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_6xuprgvctrpmuyfuh2n3ef5gpm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dYD5w0JJqR from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_6xuprgvctrpmuyfuh2n3ef5gpm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_pAEoFCjc0N from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_6xuprgvctrpmuyfuh2n3ef5gpm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_5xPhKSHemO from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_6xuprgvctrpmuyfuh2n3ef5gpm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_CmbGQmCRHy from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_DSdAmSyS6b from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_6xuprgvctrpmuyfuh2n3ef5gpm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vant_lazyload_plugin_W13KNvxip6 from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/.nuxt/vant-lazyload.plugin.mjs";
import plugin_sP80iFPEAo from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.9_rollup@4.18.0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import i18n_mkRk4pFumn from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.0_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_yY1XJTmp5W from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.12_@unocss+reset_6xuprgvctrpmuyfuh2n3ef5gpm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_blahybtbCz from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.18.0_vue@3.4.27__pinia@2.1_runjj3gkcsudmegabsrbl22ft4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import global_components_PEc2PKHunz from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/plugins/global-components.ts";
import event_bus_S6Iq1iGe5d from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/plugins/event-bus.ts";
import my_directive_DkZs1AscUH from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/plugins/my-directive.ts";
import plyr_client_AWuhaKXXrg from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/plugins/plyr.client.ts";
import tweetTracking_rwcgE0a16J from "/Users/harley/Desktop/project/mastodom/mastodon-nuxt/plugins/tweetTracking.ts";
export default [
  revive_payload_client_buxRE0OYCt,
  unhead_idEAEOk5pB,
  router_dYD5w0JJqR,
  payload_client_pAEoFCjc0N,
  check_outdated_build_client_5xPhKSHemO,
  plugin_vue3_CmbGQmCRHy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DSdAmSyS6b,
  vant_lazyload_plugin_W13KNvxip6,
  plugin_sP80iFPEAo,
  i18n_mkRk4pFumn,
  chunk_reload_client_yY1XJTmp5W,
  plugin_blahybtbCz,
  global_components_PEc2PKHunz,
  event_bus_S6Iq1iGe5d,
  my_directive_DkZs1AscUH,
  plyr_client_AWuhaKXXrg,
  tweetTracking_rwcgE0a16J
]