export const statusMap = {
  1: 'public.active',
  2: 'public.disable',
}

export const languageList = [
  {
    label: 'common.chinese',
    value: 'zh'
  },
  {
    label: 'common.english',
    value: 'en'
  }
]
